<template>
  <client-only>
    <div>
      <slot v-if="matchingQuery" />
      <slot
        v-else
        name="fallback"
      />
    </div>
  </client-only>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from '#imports'
import { functions } from '@ha/helpers'

const props = defineProps({
  query: {
    type: String,
    default: () => null
  }
})

const matchingQuery = ref(false)

const _executeQuery = () => {
  matchingQuery.value = window.matchMedia(props.query).matches
}

const executeQuery = functions.debounce(_executeQuery)

onBeforeMount(() => {
  window.addEventListener('resize', executeQuery)
  executeQuery()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', executeQuery)
})
</script>
