<template>
  <div
    class="Thumbnail Thumbnail-Project"
    @mouseenter="setIdThumbnail(hit.objectID)"
    @mouseleave="setIdThumbnail(0)"
  >
    <a
      :href="isWidgetIframe ? utmUrl : url"
      :target="isWidget ? '_blank' : '_self'"
      :data-ux="dataUx"
      @click.prevent="trackCall"
    >
      <figure class="Thumbnail--Image">
        <template v-if="image">
          <img
            :src="
              resizedSrc(image, {
                width: 290,
                height: 190
              })
            "
            :alt="alt"
            onerror="this.style.display='none';"
          />
          <span
            v-if="!hasImageDefined"
            class="Thumbnail--ImageOverlay"
          />
        </template>
        <span
          v-else
          class="Thumbnail--ImagePattern"
        />
        <span
          v-if="hit.end_date"
          class="Thumbnail--ImagePill"
        >
          <template v-if="timeLeft.count < 0">
            {{ $t('thumbnail.over') }}
          </template>
          <template v-else>
            {{ timeLeft.count }} {{ timeLeft.label }}
          </template>
        </span>
      </figure>
      <div class="Thumbnail--Content">
        <div class="Thumbnail--Numbers">
          <template v-if="hit.goal > 0 && hit.collected_amount > 0">
            <div
              :class="[
                'Thumbnail--Progress',
                {
                  'Thumbnail--Progress-Completed': isCompleted
                }
              ]"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="8"
                  stroke-width="3"
                  fill="transparent"
                />
                <circle
                  v-if="!isCompleted"
                  cx="10"
                  cy="10"
                  r="8"
                  stroke-width="3"
                  :stroke-dasharray="dashArray"
                  :stroke-dashoffset="progress"
                  fill="transparent"
                />
              </svg>
            </div>
            <p class="Thumbnail--Goal">
              {{ $t('thumbnail.funded') }}
              <span class="Thumbnail--GoalPercent">
                {{ `${progressPercent}%` }}
              </span>
              <span>·</span>
              <MatchMedia query="(min-width: 600px)">
                <span class="Thumbnail--GoalAmount">
                  {{
                    `${amountToLocaleFormattedEuros(
                      {
                        amount: hit.collected_amount,
                        areCents: true
                      },
                      locale,
                      {
                        maximumFractionDigits: 0
                      }
                    )} / ${amountToLocaleFormattedEuros(
                      {
                        amount: hit.goal,
                        areCents: true
                      },
                      locale
                    )}`
                  }}
                </span>
              </MatchMedia>
            </p>
          </template>
          <template v-else>
            <p
              v-if="
                hit?.collected_amount > 0 &&
                hit.form_type !== 'Donation'
              "
              class="Thumbnail--Collected"
            >
              <span class="Thumbnail--CollectedAmount">
                {{
                  amountToLocaleFormattedEuros(
                    {
                      amount: hit.collected_amount,
                      areCents: true
                    },
                    locale
                  )
                }}
              </span>
              {{ $t('thumbnail.collected') }}
            </p>
          </template>
        </div>
        <p class="Thumbnail--Name">
          {{ hit.title || hit.name }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { functions, haMoment } from '@ha/helpers'
import {
  cardOrigin,
  buildCardInfo
} from '@/helpers'
import { resizedSrc, amountToLocaleFormattedEuros } from '@/helpers/utils'
import MatchMedia from '@/components/MatchMedia/MatchMedia.vue'
import { useTracking } from '@ha/components-v3'
import useIsWidget from '@/composables/useIsWidget'
import useLocale from '@/composables/useLocale'
import { useI18n } from '#imports'

export default {
  name: 'ThumbnailProject',
  components: {
    MatchMedia
  },
  props: {
    hit: {
      type: [Array, Object],
      default: () => []
    },
    index: {
      type: Number,
      default: () => 0
    },
    /**
     * Used to build data-ux id tracking of thumbnails
     */
    idCarousel: {
      type: String,
      required: true,
      default: () => ''
    },
    isMapOrigin: {
      type: Boolean,
      default: () => false
    },
    contextFrom: {
      type: String,
      default: () => ''
    }
  },
  setup() {
    const { tracking } = useTracking()
    const { isWidget, isWidgetIframe } = useIsWidget()
    const locale = useLocale()

    const i18n = useI18n()

    return {
      tracking,
      get,
      amountToLocaleFormattedEuros,
      resizedSrc,
      isWidget,
      isWidgetIframe,
      locale,
      i18n
    }
  },
  computed: {
    image() {
      if (this.hit.logo) {
        return this.hit.logo
      }
      if (this.hit.banner) {
        return this.hit.banner
      }
      if (this.hasTags) {
        try {
          const suffix = this.formatCategory(
            this.hit.category_tags[0]
          )
          return `/_ex/placeholders/thumbnail/project-${suffix}.jpg`
        } catch {
          return this.placeholderFallback
        }
      }
      return this.placeholderFallback
    },
    alt() {
      return this.$t('thumbnail.projectImageAlternative', [
        this.hit.name,
        this.hit.organization_name
      ])
    },
    placeholderFallback() {
      return `/_ex/placeholders/thumbnail/project-default-${Math.floor(Math.random() * 4) + 1}.jpg`
    },
    hasImageDefined() {
      return !!get(this.hit, 'logo') || !!get(this.hit, 'banner')
    },
    hasTags() {
      return get(this.hit, 'category_tags.length', 0) > 0
    },
    dashArray() {
      return 2 * Math.PI * 10
    },
    progress() {
      const dashArray = 2 * Math.PI * 10 // 10 => radius
      const offset = this.progressPercent / 100

      return dashArray * (1 - offset)
    },
    progressPercent() {
      return parseInt(
        (get(this.hit, 'collected_amount') / get(this.hit, 'goal')) *
          100,
        10
      )
    },
    isCompleted() {
      return this.progressPercent >= 100 || this.progressPercent < 0
    },
    timeLeft() {
      const endDate = get(this.hit, 'end_date')
      if (!endDate) {
        return null
      }

      const diffYears = haMoment(endDate).diff(haMoment(), 'years')

      const diffMonths = haMoment(endDate).diff(haMoment(), 'months')

      const diffDays = haMoment(endDate).diff(haMoment(), 'days')
      const diffHours = haMoment(endDate).diff(haMoment(), 'hours')
      const diffMinutes = haMoment(endDate).diff(
        haMoment(),
        'minutes'
      )

      if (diffYears >= 2) {
        return {
          count: diffYears,
          label: `${this.$t('thumbnail.time.years', diffYears)} ${this.$t(
            'thumbnail.remainingF',
            diffYears
          )}`
        }
      }

      if (diffMonths >= 2) {
        return {
          count: diffMonths,
          label: `${this.$t('thumbnail.time.months', diffMonths)} ${this.$t(
            'thumbnail.remainingM',
            diffMonths
          )}`
        }
      }
      if (diffDays < 1 && diffHours < 1) {
        return {
          count: diffMinutes,
          label: `${this.$t('thumbnail.time.minutes', diffMinutes)} ${this.$t(
            'thumbnail.remainingF',
            diffMinutes
          )}`
        }
      }
      if (diffDays < 1 && diffMinutes >= 60) {
        return {
          count: diffHours,
          label: `${this.$t('thumbnail.time.hours', diffHours)} ${this.$t(
            'thumbnail.remainingF',
            diffHours
          )}`
        }
      }
      return {
        count: diffDays,
        label: `${this.$t('thumbnail.time.days', diffDays)} ${this.$t(
          'thumbnail.remainingM',
          diffDays
        )}`
      }
    },
    dataUx() {
      const page = this.$route.path.slice(2, 12)
      const slug = page === '/recherche' ? 'Search' : 'LP'

      let dataUx = `Explore_${slug}_ThumbnailProject_`

      if (this.idCarousel !== '') {
        dataUx += `${this.idCarousel}_`
      }
      return dataUx + this.index
    },
    url() {
      return this.isWidgetIframe
        ? this.hit.url
        : this.hit.url
            .replace('https://', '')
            .replace('www.', '')
            .replace('helloasso-dev.com', '')
            .replace('helloasso.com', '')
    },
    utmUrl() {
      return `${this.url}?utm_source=partenaire&utm_medium=referral&utm_campaign=widget&utm_content=campagne`
    }
  },
  methods: {
    formatCategory(category) {
      category = functions.deburr(
        category.split(' ').join('-').replace('&', 'et').toLowerCase()
      )

      return category
    },
    setIdThumbnail(id) {
      this.$store.commit('organizations/SET_THUMBNAIL', id)
    },
    trackCall(e) {
      const { href, target } = e.target.closest('a')

      const origin = cardOrigin(
        this.isMapOrigin,
        this.$store.getters['filters/getTab'],
        this.contextFrom
      )
      const trackInfo = buildCardInfo(this.hit, this.index, origin)

      this.tracking?.track('Explore Card Clicked', trackInfo)

      window.open(href, target, 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss">
@import './styles/thumbnail-project';
</style>
