<template>
  <div
    class="Thumbnail Thumbnail-Organization"
    @mouseenter="setIdThumbnail(hit.objectID)"
    @mouseleave="setIdThumbnail(0)"
  >
    <a
      :href="isWidgetIframe ? utmUrl : url"
      :target="isWidget ? '_blank' : '_self'"
      :data-ux="dataUx"
      @click.prevent="trackCall"
    >
      <figure class="Thumbnail--Image">
        <template v-if="banner">
          <img
            :src="
              resizedSrc(banner, {
                height: 100,
                width: 380
              })
            "
            :alt="alt"
            onerror="this.style.display='none';"
          />
          <span
            v-if="!hasImageDefined"
            class="Thumbnail--ImageOverlay"
          />
        </template>
        <span
          v-else
          class="Thumbnail--ImagePattern"
        />
      </figure>
      <figure
        :class="[
          'Thumbnail--Logo',
          { 'Thumbnail--Logo-NoLogo': !logo }
        ]"
      >
        <img
          v-if="logo"
          :src="
            resizedSrc(logo, {
              height: 96,
              width: 96
            })
          "
          :alt="alt"
          onerror="this.style.display='none';"
        />
        <span v-else>
          {{ orgFirstCharacter }}
        </span>
      </figure>
      <div class="Thumbnail--Content">
        <div class="Thumbnail--ContentWrapper">
          <span
            v-if="hit.place_city"
            class="Thumbnail--MetadataLocation"
          >
            <HaIcon :icon="faLocationDot" />
            {{ hit.place_city }}
            <template v-if="hit.place_zipcode">
              ({{ hit.place_zipcode }})
            </template>
          </span>
          <p class="Thumbnail--Name">
            {{ hit.name }}
          </p>
          <p
            v-if="hit.description"
            class="Thumbnail--Description"
          >
            {{ hit.description }}
          </p>
        </div>
        <div
          v-if="hasTagsOrActiveForms"
          class="Thumbnail--ContentWrapper"
        >
          <div
            :class="[
              'Thumbnail--Metadata',
              { 'Thumbnail--Metadata-Split': hasTags }
            ]"
          >
            <span
              v-if="hasTags"
              class="Thumbnail--MetadataTag"
            >
              {{ hit.category_tags[0] }}
            </span>
            <span
              v-if="hit.active_forms_count > 0"
              class="Thumbnail--MetadataActivityNumber"
            >
              {{ hit.active_forms_count }}
              {{
                $t(
                  'thumbnail.activityNumber',
                  hit.active_forms_count,
                  [hit.active_forms_count]
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { uniqueId } from 'lodash-es'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { functions } from '@ha/helpers'
import { resizedSrc } from '@/helpers/utils'
import { cardOrigin, buildCardInfo } from '@/helpers'
import useIsWidget from '@/composables/useIsWidget'
import { useTracking, HaIcon } from '@ha/components-v3'

export default {
  name: 'ThumbnailOrganization',
  components: {
    HaIcon
  },
  props: {
    hit: {
      type: [Array, Object],
      default: () => []
    },
    index: {
      type: Number,
      default: () => 0
    },
    /**
     * Used to build data-ux id tracking of thumbnails
     */
    idCarousel: {
      type: String,
      required: true,
      default: () => ''
    },
    isMapOrigin: {
      type: Boolean,
      default: () => false
    },
    contextFrom: {
      type: String,
      default: () => ''
    }
  },
  setup() {
    const { tracking } = useTracking()
    const { isWidget, isWidgetIframe } = useIsWidget()
    return {
      tracking,
      faLocationDot,
      resizedSrc,
      isWidget,
      isWidgetIframe
    }
  },
  data() {
    return {
      uniqueId,
      placeholderFallback:
        '/_ex/placeholders/thumbnail/organization-default-3.jpg'
    }
  },
  computed: {
    banner() {
      if (this.hit.banner) {
        return this.hit.banner
      }
      if (this.hasTags) {
        try {
          const suffix = this.formatCategory(
            this.hit.category_tags[0]
          )
          return `/_ex/placeholders/thumbnail/organization-${suffix}.jpg`
        } catch {
          return this.placeholderFallback
        }
      }
      return this.placeholderFallback
    },
    logo() {
      return this.hit.logo
    },
    alt() {
      return this.hit.name
    },
    orgFirstCharacter() {
      const name = this.hit.name || 'X'
      return name.charAt(0)
    },
    hasImageDefined() {
      return !!this.hit.banner
    },
    hasTags() {
      return (this.hit?.category_tags.length || 0) > 0
    },
    hasTagsOrActiveForms() {
      return !!this.hit.category_tags || !!this.hit.active_forms_count
    },
    dataUx() {
      const page = this.$route.path.slice(2, 12)
      const slug = page === '/recherche' ? 'Search' : 'LP'

      let dataUx = `Explore_${slug}ThumbnailOrganization_`

      if (this.idCarousel !== '') {
        dataUx += `${this.idCarousel}_`
      }
      return dataUx + this.index
    },
    url() {
      return this.isWidgetIframe
        ? this.hit.url
        : this.hit.url
            .replace('https://', '')
            .replace('www.', '')
            .replace('helloasso-dev.com', '')
            .replace('helloasso.com', '')
    },
    utmUrl() {
      return `${this.url}?utm_source=partenaire&utm_medium=referral&utm_campaign=widget&utm_content=association`
    }
  },
  methods: {
    formatCategory(category) {
      category = functions.deburr(
        category.split(' ').join('-').replace('&', 'et').toLowerCase()
      )

      return category
    },
    setIdThumbnail(id) {
      this.$store.commit('organizations/SET_THUMBNAIL', id)
    },
    trackCall(e) {
      const { href, target } = e.target.closest('a')

      const origin = cardOrigin(
        this.isMapOrigin,
        this.$store.getters['filters/getTab'],
        this.contextFrom
      )
      const trackInfo = buildCardInfo(this.hit, this.index, origin)

      this.tracking?.track('Explore Card Clicked', trackInfo)

      window.open(href, target, 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss">
@import './styles/thumbnail-organization';
</style>
