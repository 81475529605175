<template>
  <Skeleton
    v-if="isLoading"
    :index-name="indexName"
    :skeleton-number="skeletonNumber"
    :is-map-mode="isMapMode"
  />
  <ul
    v-else
    ref="list"
    :class="[
      'Hits',
      `Hits-${getThumbnailName()}`,
      { 'Hits-Carousel HorizontalScroll': isCarousel }
    ]"
  >
    <li
      v-for="(hit, index) in hits"
      :key="`hits-${index}`"
    >
      <component
        :is="getThumbnailType()"
        :index="index"
        :hit="hit"
        :id-carousel="id"
        :context-from="mode"
        @mouseover="$emit('hover', hit.objectID)"
        @mouseleave="$emit('hover', null)"
      />
    </li>
    <li
      v-if="isShowAllButtonDisplayed"
      :class="`Thumbnail Thumbnail-${getThumbnailName()} Hits--ShowAll`"
    >
      <a
        v-if="isWidget"
        :href="redirectionUrl"
        target="_blank"
        :data-ux="`Explore_LP_Carousel${getThumbnailName()}_ShowAllResults_Tile`"
      >
        {{ $t('carousel.showAll') }} ({{ nbHits }})
        <HaIcon :icon="faArrowRight" />
        <span class="visually-hidden">
          {{ $t('accessibility.openNewWindow') }}
        </span>
      </a>
      <button
        v-else
        :data-ux="`Explore_LP_Carousel${getThumbnailName()}_ShowAllResults_Tile`"
        @click="showAll()"
      >
        {{ $t('carousel.showAll') }} ({{ nbHits }})
        <HaIcon :icon="faArrowRight" />
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { HitModes } from '@/helpers'
import { HaIcon } from '@ha/components-v3'
import useConfigEnv from '@/composables/useConfigEnv'
import useIsWidget from '@/composables/useIsWidget'
import ThumbnailActivity from '@/components/Thumbnail/ThumbnailActivity.vue'
import ThumbnailOrganization from '@/components/Thumbnail/ThumbnailOrganization.vue'
import ThumbnailProject from '@/components/Thumbnail/ThumbnailProject.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import type { SEARCH_INDEX } from '~/domains/search/interfaces/search.interface'
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import { useRequestURL, useI18n } from '#imports'

const emit = defineEmits(['hover', 'list-width', 'show-all'])
const props = defineProps<{
  mode?: HitModes
  hits?: any
  nbHits?: number
  hitsPerPage?: number
  id?: string
  indexName?: SEARCH_INDEX | SEARCH_ALL_INDEX
  isLoading?: boolean
  isMapMode?: boolean
}>()

const configEnv = useConfigEnv()
const { isWidget, isWidgetIframe } = useIsWidget()
const i18n = useI18n()
const list = ref()
const searchParams = useRequestURL().searchParams

const types = {
  [SEARCH_ALL_INDEX.ORGANIZATIONS]: ThumbnailOrganization,
  [SEARCH_ALL_INDEX.ACTIVITIES]: ThumbnailActivity,
  [SEARCH_ALL_INDEX.PROJECTS]: ThumbnailProject
}

const names = {
  [SEARCH_ALL_INDEX.ORGANIZATIONS]: 'Organization',
  [SEARCH_ALL_INDEX.ACTIVITIES]: 'Activity',
  [SEARCH_ALL_INDEX.PROJECTS]: 'Project'
}

const isShowAllButtonDisplayed = computed(() => {
  // Case 1 : used in carousel, should display show all button only if enough results
  if (isCarousel.value) {
    return props?.nbHits > itemNumber.value
  }
  // Case 2 : used in hitList, should display show all button only
  if (isHitList.value) {
    return true
  }
  // Case 3 : used in infinite lists, should not display show all button
  return false
})

const isHitList = computed(() => {
  return props.mode === HitModes.HIT_LIST
})
const isCarousel = computed(() => {
  return props.mode === HitModes.CAROUSEL
})

const skeletonNumber = computed(() => props.hitsPerPage || 3)

const itemNumber = computed(() => {
  return [
    SEARCH_ALL_INDEX.ORGANIZATIONS,
    SEARCH_ALL_INDEX.ACTIVITIES
  ].includes(props.indexName as SEARCH_ALL_INDEX)
    ? 11
    : 7
})
const redirectionUrl = computed(() => {
  const isOrganization = getThumbnailName() === 'Organization'

  const isIframe = isWidgetIframe.value
  const utmURL = `&utm_source=partenaire&utm_medium=referral&utm_campaign=widget&utm_content=${isOrganization ? 'association' : 'campagne'}`
  const urlSearchParams = `${searchParams}${isIframe ? utmURL : ''}`
  return `${configEnv.NUXT_ENV_BASE_URL}/e/recherche?${urlSearchParams}`
})

const getThumbnailType = () => {
  return types[props.indexName]
}
const getThumbnailName = () => {
  return names[props.indexName]
}
const getListWidth = () => {
  const width = list.value ? list.value.scrollWidth : 0
  emit('list-width', width)
  return width
}
const showAll = () => {
  emit('show-all')
}

onMounted(() => getListWidth())
onUnmounted(() =>
  window.scrollTo({
    top: 0,
    behavior: 'instant'
  })
)
</script>

<style lang="scss">
@import './styles/hits';
</style>
